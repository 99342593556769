<template>
  <div class="grid">
    <div class="col">
      <div class="card overview-box blue">
        <div class="overview-info">
          <h6>{{ t('label.utenti', numUtenti) }}</h6>
          <h1>{{ numUtenti }}</h1>
        </div>
        <i class="pi pi-th-large"></i>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, onMounted} from 'vue';
import {useStore} from "vuex";
import {useI18n} from "vue-i18n";

const {t} = useI18n();
const store = useStore();
let numUtenti = computed(() => store.getters['utente/utentiCount']);

onMounted(() => {
  store.dispatch("utente/loadAll");
});

</script>

<style scoped>

</style>
